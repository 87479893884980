import React from 'react';
import Layout from '../components/Layout';

const EasyLanguage = () => {
  return (
    <Layout pageTitle="Kontakt" pageUrl="kontakt">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Kontakt
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <h2>Haben Sie Fragen oder Anmerkungen?</h2>
          <p>Bitte schreiben Sie uns Ihre Nachricht per E-Mail an <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a>.</p>
          <p>Als Medienvertreter nutzen Sie bitte die Seite <a href="http://cvd.bundesregierung.de/cvd-de/login" target="_blank" rel="nofollow noopener noreferrer">http://cvd.bundesregierung.de/cvd-de/login<span className="sr-only"> (öffnet ein neues Fenster)</span></a>.</p>
        </div>
      </section>
    </Layout>
  );
};

export default EasyLanguage;
